import React from "react"
import styles from "./faq.module.css"

// Material UI Theme
import { makeStyles } from "@material-ui/core/styles"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

// Changing Accordian Styles
const useStyles = makeStyles(theme => ({
  acc: {
    background: "transparent",
    boxShadow: "none",
  },
  accButton: {
    padding: "32px",
    borderBottom: "2px solid #28334A",
    "&:hover": {
      background: "#CCC",
    },
    background: "transparent",
  },
  heading: {
    fontFamily: "Montserrat",
    fontWeight: "600",
    color: "#28334A",
    fontSize: "18pt",
  },
  answer: {
    fontFamily: "Montserrat",
    fontWeight: "600",
    color: "#28334A",
    fontSize: "14pt",
    textAlign: "left",
  },
}))

const FAQ = ({ type }) => {
  const mstyle = useStyles()
  return (
    <div className={styles.container}>
      {type === 1 ? (
        <>
          <div className={styles.title}>
            <h2>Frequently Asked Questions</h2>
          </div>

          <div id="faq" className={styles.accContainer}>
            <Accordion className={mstyle.acc}>
              <AccordionSummary
                className={mstyle.accButton}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={mstyle.heading}>
                  What are the project sizes that FinSimpl can fund?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={mstyle.answer}>
                  We can fund any project between $1,000 to $100,000.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={mstyle.acc}>
              <AccordionSummary
                className={mstyle.accButton}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={mstyle.heading}>
                  Who can join FinSimpl as a contractor?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={mstyle.answer}>
                  FinSimpl works with a wide variety of contractors within the
                  home improvement and renovation space.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={mstyle.acc}>
              <AccordionSummary
                className={mstyle.accButton}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={mstyle.heading}>
                  How fast does financing take?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={mstyle.answer}>
                  The lenders FinSimpl works with promise to provide financing
                  within 2-3 business days.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={mstyle.acc}>
              <AccordionSummary
                className={mstyle.accButton}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={mstyle.heading}>
                  Is FinSimpl free to use?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={mstyle.answer}>
                  Yes, FinSimpl is completely free to use. We do not charge
                  subscription nor transaction fees and you can use our standard
                  program at no cost to you.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </>
      ) : type === 2 ? (
        <>
          <div className={styles.title}>
            <h2>Frequently Asked Questions</h2>
          </div>

          <div id="faq" className={styles.accContainer}>
            <Accordion className={mstyle.acc}>
              <AccordionSummary
                className={mstyle.accButton}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={mstyle.heading}>
                  What is FinSimpl?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={mstyle.answer}>
                  FinSimpl is a financing service that works with homeowners who
                  are seeking for any type of home improvement, construction, or
                  repair projects.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={mstyle.acc}>
              <AccordionSummary
                className={mstyle.accButton}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={mstyle.heading}>
                  How much can you borrow with FinSimpl?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={mstyle.answer}>
                  FinSimpl network of lenders extends loans from $2,000 up to
                  $50,000 for qualified consumers.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={mstyle.acc}>
              <AccordionSummary
                className={mstyle.accButton}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={mstyle.heading}>
                  For what purpose can the borrowed funds be used for?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={mstyle.answer}>
                  Funds can be used to purchase and install virtually any home
                  improvement expense.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={mstyle.acc}>
              <AccordionSummary
                className={mstyle.accButton}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={mstyle.heading}>
                  How long does the loan approval process take?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={mstyle.answer}>
                  Qualified applicants can be approved quickly during business
                  hours andmay receive their funds as soon as within 1 business
                  day.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default FAQ
