import React from "react"
import styles from "./benefits.module.css"
import image1 from "../images/benefits_one.png"
import image2 from "../images/benefits_two.png"
import image3 from "../images/benefits_three.png"

import hit1 from "../images/contractorSite/hit_1.png"
import hit2 from "../images/contractorSite/hit_2.png"
import hit3 from "../images/contractorSite/hit_3.png"

const Benefits = ({ type }) => {
  return (
    <div className={styles.container}>
      {type === 1 ? (
        <>
          <div>
            <div className={styles.title}>
              <h2>How FinSimpl Works</h2>
            </div>
            <div id="howItWorks" className={styles.flexContainer}>
              <div className={styles.benefits_card}>
                <img src={image1} alt="Make an Account" />
                <hr />
                <div className={styles.card_content}>
                  <p>
                    Make an account with us and fill out your information. We
                    will generate a custom referral link for your clients to use
                  </p>
                </div>
              </div>
              <div className={styles.benefits_card}>
                <img src={image2} alt="Share Custom Links" />
                <hr />
                <div className={styles.card_content}>
                  <p>
                    Share the custom link with your clients. They will be
                    directed to our customer portal where they can make an
                    account
                  </p>
                </div>
              </div>
              <div className={styles.benefits_card}>
                <img src={image3} alt="Fill Basic Loan Information" />
                <hr />
                <div className={styles.card_content}>
                  <p>
                    After registering, clients must fill out basic loan details
                    and they will be matched with the best lender, guaranteed
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : type === 2 ? (
        <>
          <div>
            <div className={styles.title}>
              <h2>How it Works</h2>
            </div>
            <div id="howItWorks" className={styles.flexContainer}>
              <div className={styles.benefits_card_2}>
                <img src={hit1} alt="Make an Account" />
                <div className={styles.card_content_2}>
                  <p>Apply in seconds without impacting your credit score</p>
                </div>
              </div>
              <div className={styles.benefits_card_2}>
                <img src={hit2} alt="Share Custom Links" />
                <div className={styles.card_content_2}>
                  <p>Compare your loan rates from multiple lenders</p>
                </div>
              </div>
              <div className={styles.benefits_card_2}>
                <img src={hit3} alt="Fill Basic Loan Information" />
                <div className={styles.card_content_2}>
                  <p>Get approved and funded withing 1-3 days</p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default Benefits
